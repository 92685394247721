import React, { Component } from "react"
import { graphql } from "gatsby"

import ContactFooter from '../components/contactfooter'
import ScroolTop from '../components/scrolltop'
import { removeSpecialSymbols } from '../util/common';
import SEO from '../components/seo'
import Typed from 'react-typed';

class ServicesTemplate extends Component {
  
    render() {
        const data = this.props.data
        const banner = data.wordpressPage.acf
        const post = data.allWordpressWpService
        const page = this.props.data.wordpressPage
        const pageacf = page.acf.content_modules_page[0];  
        
        var oldString = banner.banner_blink_word;
        var mynewarray=oldString.split(',')
      return (
        <>
            <SEO title="Services" />
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{banner.banner_content} <Typed strings={mynewarray} typeSpeed={120} backSpeed={50} cursorChar= {'_'} loop /></h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="services-page">
                <div className="services-top-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-content">
                                    <p>{pageacf.top_sec_left_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-list-wrap">
                    <div className="container">
                        <div className="services-list">                            
                            {post.edges.map((node,index) => (
                                <div id={node.node.slug} className={index % 2 ? 'service-box even' : 'service-box odd'} key={index}>
                                <div className="row">
                                    <div className="col-md-6 service-content-wrap">
                                        <div className="service-content">
                                            <h2 className="ser-title">{`${removeSpecialSymbols(node.node.title)}`}</h2>   
                                            <div className="service-image">
                                                {node.node.featured_media !== null && node.node.featured_media.source_url !== null &&
                                                    <img src={node.node.featured_media.source_url} alt={node.node.title}/>                                  
                                                }
                                                <div className="image-back-layer"></div>
                                            </div>                                         
                                            {node.node.acf.service_short_description !== null &&
                                                <div className="ser-desc" dangerouslySetInnerHTML={{ __html: node.node.acf.service_short_description }} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 service-image-wrap">
                                        <div className="service-image">
                                            {node.node.featured_media !== null && node.node.featured_media.source_url !== null &&
                                                <img src={node.node.featured_media.source_url} alt={node.node.title}/>                                  
                                            }
                                            <div className="image-back-layer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}                           
                        </div>
                    </div>
                </div>
            </section>
            <ContactFooter />
            <ScroolTop />
        </>
        )
  }

}


export default ServicesTemplate

export const pageQuery = graphql`
query($skip: Int , $limit: Int) {
    
    allWordpressWpService(limit: $limit, skip: $skip, sort: {order: DESC, fields: date}) {
        edges {
          node {
            id
            slug
            title
            featured_media {
              source_url
            }
            acf {
                service_short_description                
            }
          }
        }
    }
    wordpressPage(wordpress_id: {eq: 13}) {
        id
        slug
        title
        acf {
          banner_heading
          banner_content
          banner_blink_word
          content_modules_page {
            ... on WordPressAcf_top_section_with_left_right_content {
              id              
              top_sec_heading
              top_sec_left_content
              top_sec_right_content
            }
          }
        }
      }
    
  
}
`
