import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { removePre } from './../util/common';
import axios from 'axios'


  const validEmailRegex = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);

  class ContactFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            status: "",
            fullname: null,
            email: null,
            message: null,
            errors: {
            fullname: '',
            email: '',
            message: '',
          }
        };
    }

    allFieldsValid() {
        const fields = ['fullname','email','message'];
        const errors = {};
        
        fields.forEach((field) => {
          const value = this.state[field];
    
          if (!value || value === '') {
            errors[field] = "This field cannot be blank.";
          } 
          
          if (field === "email") {
            if(!value || value === '') {
              errors.email = "This field cannot be blank.";
            } else if (!value.match(validEmailRegex)) {
              errors.email = "Please enter a valid email address";
            } 
          }
        }); 
       
        this.setState({ errors });
        return Object.keys(errors).length === 0;
      }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({[name]: value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(this.allFieldsValid()) {
      const { fullname, email, message } = this.state;
      axios.post('https://static.finntia.com/wp-json/finntia/v1/contact-footer', {
        'fullname': fullname,
        'email': email,
        'message': message  
      })
      .then((response) => {
        this.setState({
          submitted: true,
          status: "Thank you. We've received your Inquiry. We'll get back to you soon."
        });
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      this.setState({
        submitted: false,
        status: "There was a problem with your submission. Errors are marked below."
      });
    }
  }
  
    render() {
        const { data } = this.props;         
        // const ctadata = data.allWordpressAcfOptions.edges[0].node.options
        return (
          
            <div className="contact-form-bottom">
              <div className="container">
                <div className="contact-form-bottom-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <h1>Need a quote? Just ask...</h1>

                      <p>Creating future-proof digital experiences is what we do. Tell us about your needs, We'd love to collaborate with you.</p>
                      <div className="btn">
                        <Link to={`${removePre('/contact-us')}`}>Contact Us</Link>
                        </div>

                    </div>
                  </div>
                </div>
              </div>              
            </div>
            
        );
    }
  }
  export default props => (
  <StaticQuery
  query={graphql`
  {
    allWordpressAcfOptions {
      edges {
        node {
          options {
            contact_content
            contact_heading
            contact_section_title
          }
        }
      }
    }
  }
  `}
  render={
      data => <ContactFooter data={data}
       {...props} />}
  />
  );
